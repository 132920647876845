<template>
  <menikheim-layout>
    <template #content>
      <ArticleComponent v-for="article in getArticles" :key="article.caption" :article="article" />
    </template>
  </menikheim-layout>
</template>

<script>
import ArticleComponent from "@/components/ArticleComponent.vue";
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";

export default {
  name: "MenikheimPage",
  components: {
    MenikheimLayout,
    ArticleComponent
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getArticles() {
      return this.$store.getters.articles;
    }
  },
  data() {
    return {
      activeView: "MandantSelectorComponent",
    };
  },
};
</script>

<style scoped>

</style>
