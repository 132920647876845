<template>
  <menikheim-layout>
    <template #content>
      <div class="container">

        <h1><span class="text-highlight">{{ project.name.substring(0,1) }}</span>{{ project.name.substring(1) }}</h1>
        <p>Leistungen im Projekt:<br />{{ project.description }}</p>
        <lightgallery
            :settings="{ speed: 500, plugins: plugins }"
            :onBeforeSlide="onBeforeSlide"
            :onInit="onInit"
        >
            <a v-for="item in items" :key="item.id" :href="item.src">
              <img :src="item.src" :alt="item.id" width="200px" class="rounded" style="margin:5px;"/>
            </a>

        </lightgallery>
      </div>
    </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
let lightGallery = null;

export default {
  name: "ReferenzenPage",
  components: {
    MenikheimLayout,
    Lightgallery,
  },
  watch: {
        project() {
            this.$nextTick(() => {
                lightGallery.refresh();
                console.log("refresh done")
            });
        },
    },
  data() {
    return {
      plugins: [lgThumbnail, lgZoom],
      project: {
        name: "Projekt unbekannt...",
        images: [{name: "Is loading...", image: require('@/assets/images/article/althuette_2-0f003e2a.jpg')}]
      },
      items: [
            {
                src: require('@/assets/images/althuette_festhalle/althuette_1.jpg'),
                id: 'img1'
            },
            {
                src: require('@/assets/images/althuette_festhalle/althuette_2.jpg'),
                id: 'img2'
            },
        ],
    };
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {

  },
  methods: {
    onInit: (detail) => {
        console.log('lightGallery has been initialized');
        lightGallery = detail.instance;
    },
    onBeforeSlide: () => {
        console.log('calling before slide');
    },
  },
  created() {
    this.project = this.$store.getters.projectById(this.$route.params.projectid) || this.project;
    this.items = this.project.images;
  },
  mounted() {


    lightGallery.refresh();
  },

};
</script>

<style scoped>
  @import 'lightgallery/css/lightgallery.css';
  @import 'lightgallery/css/lg-thumbnail.css';
  @import 'lightgallery/css/lg-zoom.css';

  .lg-custom-thumbnails {
    max-width: 200px;
  }
  .gallery-item {
    margin: 5px;
  }
</style>
