<!-- eslint-disable vue/no-unused-vars -->
<template>
  <menikheim-layout>
    <template #content>
      <h1><span class="text-highlight">U</span>nsere Leistungen im Innenbereich</h1>
      <div class="container">
        <div class="row">


            <LeistungComponent v-for="(leistung, key) in leistungen" :key="leistung.caption" :leistung="leistung" :last="isLast(key)" />

        </div>
      </div>
    </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";
import LeistungComponent from "@/components/LeistungComponent.vue";

export default {
  name: "InnenLeistungenPage",
  components: {
    MenikheimLayout,
    LeistungComponent
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getArticles() {
      return this.$store.getters.articles;
    },

  },
  methods: {
    isLast(i) {
      return i != Object.keys(this.leistungen).length - 1;
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      leistungen: [
        {
          caption: "Innenputze",
          image: "innenputz.jpg",
          content: "Gipsputz, Kalkputz, Kalkzementputz, Lehmputz"
        },{
          caption: "Putzsystem Rotkalk",
          image: "rotkalk_innen.jpg",
          content: `Nachhaltiger Baustoff für ein gesundes Raumklima, Luftfeuchtigkeit regulierend, aktiver Schutz vor Schimmel, detaillierte Informationen finden Sie <a href="${this.publicPath}/Rotkalk_Magazin.pdf" target="_blank">hier</a>`
        },{
          caption: "Dekor",
          image: "spachteltechnik.jpg",
          content: "Dekorative Oberputze, Gebürstete Innenwandputze, Spachtel- und Lasurtechniken"
        },{
          caption: "Estrich",
          image: "fliessestrich.jpg",
          content: "Fließestriche, Schnellestriche, Industrieestriche, Trockenestriche"
        },{
          caption: "Stuckarbeiten",
          image: "stuck_1.jpg",
          content: "Ornamente, Stuckleisten, Eckgesimse, Säulen"
        }
      ]
    };
  },
};
</script>

<style scoped>

</style>
