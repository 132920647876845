<template>
  <li :class="getNavClass">
    <router-link :to="path">{{ elementCaption }}</router-link>
  </li>
</template>

<script>
export default {
  name: "NavigationElement",
  data() {
    return {};
  },
  props: {
    elementCaption: {
      type: String,
      default: "Missing caption"
    },
    activeCaption: {
      type: String,
      default: "/Home",
    },
    path: {
      type: String,
      default: "/"
    }
  },
  computed: {
    getNavClass() {
      return this.activeCaption == this.elementCaption ? "active" : "";
    }
  },
  methods: {
  }
}
</script>

<style>

</style>
