import { createRouter, createWebHistory } from "vue-router";

import store from "../store";
const router = createRouter({
  // Optionen
  history: createWebHistory(),
  routes: store.getters.navigationAllElements,
});
console.log();

export default router;
// CreateWebHistory
// -> www.shop.de/auth/login

// CreateWebHashHistory
// -> www.shop.de/index.html/#/auth/login
