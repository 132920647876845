<template>
  <menikheim-layout>
    <template #content>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1><span class="text-highlight">I</span>mpressum</h1>
            <h2>Menikheim <span class="text-highlight">Stuckateur</span> GmbH</h2>
            <p>Brühlstr. 20<br />73635 Rudersberg<br />Telefon: 07183 / 8428<br />Telefax: 07183 / 7489</p>
            <p>Ust.-Id.: DE 291 263 698</p>
            <p>E-Mail: <a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a><br />Internet: <a href="http://menikheim-stuck.de/">menikheim-stuck.de</a></p>
            <p>Sitz in Rudersberg – Registergericht Stuttgart HRB 745903<br />Handwerkskammer Stuttgart<br />Geschäftsführer: Steffen Menikheim</p>
            <h3><span class="text-highlight">I</span>nhaltlich Verantwortlicher  gemäß § 6 MDStV:</h3>
            <p>Menikheim <span class="text-highlight">Stuckateur</span> GmbH</p>
            <h2><span class="text-highlight">H</span>aftungsausschluss</h2>
            <h3><span class="text-highlight">H</span>aftung für Inhalte</h3>
            <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. </p>
            <h3><span class="text-highlight">H</span>aftung für Links</h3>
            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
            <h3><span class="text-highlight">U</span>rheberrecht</h3>
            <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
            <h3><span class="text-highlight">S</span>treitschlichtung</h3>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <p style="float:right;font-size: -0.3em;font-style: italic;">Quelle: https://www.e-recht24.de/impressum-generator.html</p>
          </div>
        </div>
      </div>
    </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";

export default {
  name: "ImpressumPage",
  components: {
    MenikheimLayout
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getArticles() {
      return this.$store.getters.articles;
    }
  },
  data() {
    return {
      activeView: "MandantSelectorComponent",
    };
  },
};
</script>

<style scoped>

</style>
