<template>
  <div class="col-12" style="display:block">
    <div style="height:180px">
      <img :src="require(`@/assets/images/article/${leistung.image}`)" alt="Innenputze" width="200px"
        style="float:left;margin:15px;object-fit: contain;" />
      <h3><span class="text-highlight">{{ leistung.caption.substring(0,1) }}</span>{{ leistung.caption.substring(1) }}</h3>
      <p v-html="leistung.content"></p>
    </div>
    <hr v-if="last" />
  </div>
</template>

<script>

export default {
  name: "LeistungComponent",
  props: {
    leistung: {
      type: Object,
      default() {
        return {
          caption: "not defined",
          image: "not_found.jpg"
        }
      }
    },
    last: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped></style>