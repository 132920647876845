<template>
  <div class="col-lg-4 col-mg-12">
    <div class="teaser">
      <img
      :src="require(`@/assets/images/article/${article.image}`)"
      class="rounded" />
    </div>
    <h2><span class="capital">{{ article.caption.substring(0,1) }}</span>{{ article.caption.substring(1) }}</h2>
    <p>{{ article.intro }} </p>
  </div>
</template>

<script>
export default {
  name: "ArticleComponent",
  data() {
    return {}
  },
  props: {
    article: {
      type: Object,
      default() {
        return {
          caption: "Empty Caption",
          intro: "This could be your into text",
          image: "bauen.jpg"
        }
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped></style>