import { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import store from '@/store'
import router from '@/router'

window.$ = window.jQuery = require('jquery');

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies, {expires: Infinity});
app.mount('#app');

