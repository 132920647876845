
const state = {
  projects: [
    {
      projectid: "Althuette",
      name: "Festhalle Althütte",
      description: "Innenputz im Innenbereich | Gerüstbau | Energetische Gebäudesanierung - Wärmedämmverbundsystem aus Polystyrol im Außenbereich",
      images: [
        {id:'1', size: '1400-800', name: "Image1", src: require("@/assets/images/althuette_festhalle/althuette_1.jpg"), thumb: require("@/assets/images/althuette_festhalle/althuette_1.jpg")},
        {id:'2', size: '1400-800', name: "Image2", src: require("@/assets/images/althuette_festhalle/althuette_2.jpg"), thumb: require("@/assets/images/althuette_festhalle/althuette_1.jpg")},
        {id:'3', size: '1400-800', name: "Image3", src: require("@/assets/images/althuette_festhalle/althuette_3.jpg"), thumb: require("@/assets/images/althuette_festhalle/althuette_1.jpg")},
        {id:'4', size: '1400-800', name: "Image4", src: require("@/assets/images/althuette_festhalle/althuette_teaser.jpg"), thumb: require("@/assets/images/althuette_festhalle/althuette_1.jpg")},
      ]
    },
    {
      projectid: "Baumit",
      name: "Fortbildung bei Baumit 2012",
      description: "Fortbildung und Betriebsausflug nach Hindelang.",
      images: [
        {id:'1', size: '1400-800', name: "Image1", src: require("@/assets/images/baumit/SAM_0775.jpg"), thumb: require("@/assets/images/baumit/SAM_0775.jpg")},
        {id:'2', size: '1400-800', name: "Image2", src: require("@/assets/images/baumit/SAM_0777.jpg"), thumb: require("@/assets/images/baumit/SAM_0777.jpg")},
        {id:'3', size: '1400-800', name: "Image3", src: require("@/assets/images/baumit/SAM_0779.jpg"), thumb: require("@/assets/images/baumit/SAM_0779.jpg")},
        {id:'4', size: '1400-800', name: "Image4", src: require("@/assets/images/baumit/SAM_0780.jpg"), thumb: require("@/assets/images/baumit/SAM_0780.jpg")},
      ]
    },
    {
      projectid: "Jugendhaus",
      name: "Jugendhaus in Rudersberg",
      description: "Altbau aus dem Jahr 1633 für die Jugend der Gemeinde Rudersberg modernisiert | Gerüstbau | Fassade mit Wärmedämmputz | Putz- und Farbgestaltung im Außenbereich",
      images: [
        {id:'1', size: '1400-800', name: "Image1", src: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_1.jpg"), thumb: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_1.jpg")},
        {id:'2', size: '1400-800', name: "Image2", src: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_2.jpg"), thumb: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_2.jpg")},
        {id:'3', size: '1400-800', name: "Image3", src: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_3.jpg"), thumb: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_3.jpg")},
        {id:'4', size: '1400-800', name: "Image4", src: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_4.jpg"), thumb: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_4.jpg")},
        {id:'4', size: '1400-800', name: "Image4", src: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_5.jpg"), thumb: require("@/assets/images/jugendhaus/jugendhaus_rudersberg_5.jpg")},
      ]
    },
    {
      projectid: "Kinderhaus",
      name: "Kinderhaus Welzheim",
      description: "Erweiterung des Kindergartens Welzheim zur Kindertagesstätte",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/kinderhaus/kinderhaus-weilheim_1.jpg")},
        {id:'2', name: "Image2", src: require("@/assets/images/kinderhaus/kinderhaus-weilheim_2.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/kinderhaus/kinderhaus-weilheim_4.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/kinderhaus/kinderhaus-weilheim_5.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/kinderhaus/kinderhaus-weilheim_6.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/kinderhaus/kinderhaus-weilheim_7.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/kinderhaus/kinderhaus-weilheim_teaser.jpg")},
      ]
    },
    {
      projectid: "Alfdorf",
      name: "Rathaus Alfdorf",
      description: "Innen- und Außenputzarbeiten | Trockenbau | Akustikbau ",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_1.jpg")},
        {id:'2', name: "Image2", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_2.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_3.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_4.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_5.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_6.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_7.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_8.jpg")},
        {id:'4', name: "Image4", src: require("@/assets/images/rathaus_alfdorf/rathaus_alfdorf_teaser.jpg")},
      ]
    },
    {
      projectid: "Rudersberg",
      name: "Rathaus Rudersberg",
      description: "Sanierung des Wärmedämmverbundsystems mittels einer Gewebearmierung neuem Oberputz und Farbgestaltung",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/rathaus_rudersberg/rathaus_rudersberg_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/rathaus_rudersberg/rathaus_rudersberg_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/rathaus_rudersberg/rathaus_rudersberg_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/rathaus_rudersberg/rathaus_rudersberg_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/rathaus_rudersberg/rathaus_rudersberg_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/rathaus_rudersberg/rathaus_rudersberg_teaser.jpg")},
      ]
    },
    {
      projectid: "VobaWelzheim",
      name: "Volksbank Welzheim",
      description: "Innenputz | Trockenbau | Energetische Fassadensanierung - Wärmedämmverbundsystem",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/volksbank_welzheim/voba_welzheim_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/volksbank_welzheim/voba_welzheim_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/volksbank_welzheim/voba_welzheim_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/volksbank_welzheim/voba_welzheim_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/volksbank_welzheim/voba_welzheim_teaser.jpg")},
      ]
    },
    {
      projectid: "KaercherWinnenden",
      name: "Kärcher Winnenden",
      description: "Trockenbau | Innenputzarbeiten",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium_teaser.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium-1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium-2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium-4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium-5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium-7.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium-9.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kaercher/kaercher_auditorium-11.jpg")},
      ]
    },
    {
      projectid: "EdekaRudersberg",
      name: "Edeka Rudersberg",
      description: "Innen- und Außenputzarbeiten ",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/edeka_rudersberg/edeka_ru_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/edeka_rudersberg/edeka_ru_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/edeka_rudersberg/edeka_ru_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/edeka_rudersberg/edeka_ru_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/edeka_rudersberg/edeka_ru_teaser.jpg")},
      ]
    },
    {
      projectid: "ZottFitness",
      name: "ZOTT Fitnessclub",
      description: "Innenputzarbeiten | Wärmedämmverbundsystem | besondere Herausforderung an diesem Objekt der Hellbezugswert",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/zott_fitnessclub/zot_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/zott_fitnessclub/zot_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/zott_fitnessclub/zot_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/zott_fitnessclub/zot_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/zott_fitnessclub/zot_teaser.jpg")},
      ]
    },
    {
      projectid: "WohnRudersberg",
      name: "Wohnhaus Rudersberg",
      description: "Innen- und Außenputzarbeiten | Gerüstbau | Wärmedämmverbundsystem | Farbgebung",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/wohnhaus_rudersberg/ru_eigen_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnhaus_rudersberg/ru_eigen_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnhaus_rudersberg/ru_eigen_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnhaus_rudersberg/ru_eigen_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnhaus_rudersberg/ru_eigen_teaser.jpg")},
      ]
    },
    {
      projectid: "WohnSchorndorf",
      name: "Wohnanlage Schorndorf",
      description: "Innenputzarbeiten | Trockenbau | Tiefgaragendecken-Dämmung | Gerüstbau | Wärmedämmverbundsystem | Farbgestaltung | Fassadenanstrich",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/wohnanlage_schorndorf/scheffelweg_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnanlage_schorndorf/scheffelweg_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnanlage_schorndorf/scheffelweg_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnanlage_schorndorf/scheffelweg_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnanlage_schorndorf/scheffelweg_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnanlage_schorndorf/scheffelweg_6.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/wohnanlage_schorndorf/scheffelweg_teaser.jpg")},
      ]
    },
    {
      projectid: "Fellbach",
      name: "Hölderlin Carre in Fellbach",
      description: "Innenputzarbeiten | Tiefgaragendecken-Dämmung | Wärmedämmverbundsystem | Farbgestaltung | Fassadenanstrich",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_6.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_7.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_8.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_9.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_10.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/hoelderlin/hoelderlin_teaser.jpg")},
      ]
    },
    {
      projectid: "Roemer",
      name: "Römer Residenz",
      description: "Innenputzarbeiten | Trockenbau | Tiefgaragendecken-Dämmung | Wärmedämmverbundsystem | Farbgestaltung | Fassadenanstrich",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/roemer_residenz/residenz_ro_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/roemer_residenz/residenz_ro_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/roemer_residenz/residenz_ro_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/roemer_residenz/residenz_ro_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/roemer_residenz/residenz_ro_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/roemer_residenz/residenz_ro_6.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/roemer_residenz/residenz_ro_teaser.jpg")},
      ]
    },
    {
      projectid: "KircheWelzheim",
      name: "Kirche Welzheim",
      description: "Gerüstbau | Sanierung des Fassadenputzes",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/kirche_welzheim/kirche_welzheim_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirche_welzheim/kirche_welzheim_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirche_welzheim/kirche_welzheim_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirche_welzheim/kirche_welzheim_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirche_welzheim/kirche_welzheim_teaser.jpg")},
      ]
    },
    {
      projectid: "KirchturmRudersberg",
      name: "Kirchturm Rudersberg",
      description: "Gerüstbau | Fassaden- und Fachwerksanierung",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/kirchturm_rudersberg/kirchturm_ru_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirchturm_rudersberg/kirchturm_ru_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirchturm_rudersberg/kirchturm_ru_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirchturm_rudersberg/kirchturm_ru_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirchturm_rudersberg/kirchturm_ru_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/kirchturm_rudersberg/kirchturm_ru_teaser.jpg")},
      ]
    },
    {
      projectid: "HausRudersberg",
      name: "Historisches Haus Rudersberg",
      description: "Gerüstbau | Fenster- und Dachsanierung | Wärmedämmverbundsystem aus Mineralwolldämmplatten",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/historisches_haus_rudersberg/muehlbachweg_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/historisches_haus_rudersberg/muehlbachweg_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/historisches_haus_rudersberg/muehlbachweg_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/historisches_haus_rudersberg/muehlbachweg_teaser.jpg")},
      ]
    },
    {
      projectid: "CafeAmMarkt",
      name: "Cafe am Markt",
      description: "Gerüstbau | Fassadenrenovierung | Fachwerksanierung",
      images: [
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_1.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_2.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_3.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_4.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_5.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_6.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_7.jpg")},
        {id:'1', name: "Image1", src: require("@/assets/images/cafe_am_markt/cafe_am_markt_teaser.jpg")},
      ]
    },
  ]
};
const mutations = {

};
const actions = {

};
const getters = {
  projects: (state) => state.projects,
  projectById: (state) => (id) => state.projects.find((e) => e.projectid == id),
};

const projectModule = {
  state,
  mutations,
  actions,
  getters,
};

export default projectModule;
