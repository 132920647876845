
import MenikheimPage from "@/pages/MenikheimPage.vue";
import ImpressumPage from "@/pages/ImpressumPage.vue";
import FirmaUnsPage from "@/pages/FirmaUnsPage.vue";
import InnenLeistungenPage from "@/pages/InnenLeistungenPage.vue";
import AussenLeistungenPage from "@/pages/AussenLeistungenPage.vue";
import AltbauLeistungenPage from "@/pages/AussenLeistungenPage.vue";
import ReferenzenPage from "@/pages/ReferenzenPage.vue";
import KontaktPage from "@/pages/KontaktPage.vue";
import ProjectDetailPage from "@/pages/ProjectDetailPage.vue";
import EventDetailPage from "@/pages/EventDetailPage.vue";

const state = {
  navigationElements: [
  {
    parent: "/",
    caption: "Home",
    path: "/Home",
    alias: ["/"],
    component: MenikheimPage,
    navSlider: "Home"
  },
  {
    parent: "/",
    caption: "Die Firma",
    path: "/Firma",
    alias: ["/Firma/Uns"],
    component: FirmaUnsPage,
    navSlider: "Die Firma"
  },
  {
    parent: "/",
    caption: "Leistungen",
    path:"/Leistungen",
    alias: [],
    component: InnenLeistungenPage,
    navSlider: "Leistungen"
  },
  {
    parent: "/Leistungen",
    caption: "Innen Leistungen",
    path:"/Leistungen/Innen",
    alias: [],
    component: InnenLeistungenPage,
    navSlider: "Leistungen"
  },
  {
    parent: "/Leistungen",
    caption: "Außen Leistungen",
    path:"/Leistungen/Aussen",
    alias: [],
    component: AussenLeistungenPage,
    navSlider: "Leistungen"
  },
  {
    parent: "/Leistungen",
    caption: "Außen Leistungen",
    path:"/Leistungen/Altbau",
    alias: [],
    component: AltbauLeistungenPage,
    navSlider: "Leistungen"
  },
  {
    parent: "/",
    caption: "Referenzen",
    path:"/Referenzen",
    alias: [],
    component: ReferenzenPage,
    navSlider: "Referenzen"
  },
  {
    parent: "/",
    caption: "Kontakt",
    path:"/Kontakt",
    alias: [],
    navSlider: "Kontakt",
    component: KontaktPage
  },
  {
    parent: "/Kontakt",
    caption: "Impressum",
    path: "/Impressum",
    alias: [],
    component: ImpressumPage,
    navSlider: "Kontakt"
  },
  {
    parent: "-",
    caption: "Projekt",
    path: "/Projekt/:projectid",
    alias: [],
    navSlider: "Referenzen",
    component: ProjectDetailPage
  },
  {
    parent: "-",
    caption: "Veranstaltung",
    path: "/Veranstaltung/:eventid",
    alias: [],
    navSlider: "Die Firma",
    component: EventDetailPage
  }
],
}
const getters = {
  navigationAllElements: (state) => state.navigationElements,
  navigationElements: (state) => (parent) => {
    return state.navigationElements.filter((e) => {
      return e.parent == parent;
    });
  },
  navigationElementsByPath: (state) => (path) => {
    let pathElements = state.navigationElements.filter((e) => {
      return e.path == path;
    })
    if(pathElements.length > 0) {
      return pathElements[0];
    } else {
      return state.navigationElements.find((e) => {
        return e.alias.includes(path);
      });
    }
  }
}
const mutations = {};
const actions = {};

const schichtModule = {
  state,
  mutations,
  actions,
  getters
}

export default schichtModule;