<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~animate.css/animate.css";

@font-face {
  font-family: "rokkitt";
  src: url("@/assets/rokkitt-regular.woff") format("woff"), url("@/assets/rokkitt-regular.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "menikheim-bold";
  src: url("@/assets/menikheim-bold.woff") format("woff"), url("@/assets/menikheim-bold.ttf") format("truetype");
  font-display: block;
}

body {
  font-family: rokkitt, serif;
  background-color: rgb(var(--color-background));
  color: rgb(var(--color-text));
}

h1,h2,h3,h4,h5 {
  font-family: menikheim-bold, sans-serif;
}

a {
  color: rgb(var(--color-schwarz));
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.text-highlight {
  color: rgb(var(--color-highlight1));
}

.text-highlight-grau {
  color: rgb(var(--color-highlight2));
}

.btn-menikheim {
  --bs-btn-color: rgb(var(--color-menikheim-weiss));
  --bs-btn-bg: rgb(var(--color-highlight1));
  --bs-btn-border-color: rgb(var(--color-grau));
  --bs-btn-hover-color: rgb(var(--color-grau));
  --bs-btn-hover-bg: rgb(var(--color-rot-hell));
  --bs-btn-hover-border-color: rgb(var(--color-grau));
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: rgb(var(--color-grau));
  --bs-btn-active-bg: rgb(var(--color-red));
  --bs-btn-active-border-color: rgb(var(--color-red));
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

:root {

  --color-background: var(--color-menikheim-weiss);
  --color-text: var(--color-schwarz);
  --color-highlight1: var(--color-menikheim-orange);
  --color-highlight2: var(--color-menikheim-blau);

  --color-blau: 20, 68, 149;
  --color-blau-hell: 180, 204, 245;
  --color-gelb: 251, 212, 4;
  --color-gelb-hell: 253, 232, 119;
  --color-rot: 220, 61, 5;
  --color-rot-hell: 240, 140, 140;
  --color-gruen: 90, 240, 90;
  --color-gruen-hell: 140, 240, 140;
  --color-grau: 51, 51, 51;
  --color-weiss: 204, 204, 204;
  --color-schwarz: 0, 0, 0;

  --color-menikheim-orange: 255, 103, 00;
  --color-menikheim-blau: 3, 78, 162;
  --color-menikheim-weiss: 255, 255, 255;

  --color_0: 255, 255, 255; /* weiß */
  --color_6: 255, 255, 255;
  --color_4: 251, 251, 251;
  --color_26: 255, 255, 255;
  --color_27: 251, 251, 251;
  --color_28: 219, 219, 219;

  --color_32: 251, 212, 4; /* gelb */
  --color_5: 251, 212, 4;
  --color_31: 253, 232, 119;

  --color_15: 20, 68, 149; /* blau */
  --color_1: 20, 68, 149;
  --color_25: 26, 89, 194;
  --color_2: 166, 180, 216; /* hellblau */
  --color_7: 180, 204, 245;
  --color_8: 105, 153, 235;

  --color_9: 30, 102, 224;
  --color_10: 20, 68, 149;
  --color_11: 255, 255, 255;
  --color_12: 180, 204, 245;
  --color_13: 105, 153, 235;
  --color_14: 30, 102, 224;
  --color_16: 245, 247, 251;
  --color_17: 215, 222, 238;
  --color_18: 166, 180, 216;
  --color_19: 97, 122, 186;
  --color_20: 47, 64, 108;
  --color_21: 255, 255, 255;
  --color_22: 255, 255, 255;
  --color_23: 244, 248, 254;
  --color_24: 146, 181, 240;
  --color_29: 166, 166, 166;
  --color_30: 93, 93, 93;
  --color_31: 253, 232, 119;
  --color_33: 157, 132, 3;
  --color_34: 84, 71, 1;
  --color_35: 31, 26, 0;
  --font_0: normal normal normal 22px/1.41em Futura-LT-W01-Book, sans-serif;
  --font_2: normal normal normal 28px/1.375em futura-lt-w01-book, futura-lt-w05-book, sans-serif;
  --font_3: normal normal normal 88px/1.2em futura-lt-w01-book, futura-lt-w05-book, sans-serif;
  --font_4: normal normal normal 72px/1.25em futura-lt-w01-book, futura-lt-w05-book, sans-serif;
  --font_5: normal normal normal 50px/1.34em futura-lt-w01-book, futura-lt-w05-book, sans-serif;
  --font_6: normal normal normal 40px/1.35em futura-lt-w01-book, futura-lt-w05-book, sans-serif;
  --font_1: normal normal normal 14px/1.79em helvetica-w01-light, helvetica-w02-light, sans-serif;
  --font_7: normal normal normal 20px/1.67em helvetica-w01-light, helvetica-w02-light, sans-serif;
  --font_8: normal normal normal 18px/1.75em helvetica-w01-light, helvetica-w02-light, sans-serif;
  --font_9: normal normal normal 15px/1.875em helvetica-w01-light, helvetica-w02-light, sans-serif;
  --font_10: normal normal normal 14px/1.79em helvetica-w01-light, helvetica-w02-light, sans-serif;
}
</style>
