import { createStore } from "vuex";

import navModule from "./module/nav";
import articlesModule from "./module/article";
import projectModule from "./module/project";
import eventModule from "./module/event";

const store = createStore({
  modules: {
    articles: articlesModule,
    nav: navModule,
    projects: projectModule,
    events: eventModule,
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters: {

  },
});

export default store;
