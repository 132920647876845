<template>
  <div class="col-12" style="display:block">
    <div style="height:180px">
      <img :src="event.image" alt="Innenputze" width="200px"
        style="float:left;margin:15px;object-fit: contain;" class="rounded"/>
      <h3><span class="text-highlight">{{ event.caption.substring(0,1) }}</span>{{ event.caption.substring(1) }}</h3>
      <p v-html="event.content"></p>
      <button v-if="event.showButton" type="button" class="btn btn-menikheim" @click="$router.push(event.link)">Zur Veranstaltung</button>
    </div>
    <hr v-if="last" />
  </div>
</template>

<script>

export default {
  name: "EventComponent",
  props: {
    event: {
      type: Object,
    },
    last: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped></style>