<template>
  <menikheim-layout>
    <template #content>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1><span class="text-highlight">&Ouml;</span>ffentliche Projekte</h1>
            <p></p>
            <carousel :items-to-show="1" :wrapAround="true" :autoplay="7500" :pauseAutoplayOnHover="true" style="margin-top:2em;margin-bottom:2em;">
              <slide v-for="slide in carouselElements" :key="slide">
                <div style="text-align: left; width:80%">
                  <img :src="slide.image" style="float: right; height: 200px;" class="rounded" />
                  <h2><span class="text-highlight">{{ slide.caption.substring(0,1) }}</span>{{ slide.caption.substring(1) }}</h2>
                  <p>{{ slide.subText }}</p>
                  <button v-if="slide.showProject" type="button" @click="$router.push(slide.link)" class="btn btn-menikheim">Zum Projekt</button>
                </div>
              </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
            <h1><span class="text-highlight">R</span>eferenzen aus
              Firmen und Industrie</h1>
            <p></p>
            <carousel :items-to-show="1" :wrapAround="true" :autoplay="7500" :pauseAutoplayOnHover="true" style="margin-top:2em;margin-bottom:2em;">
              <slide v-for="slide in firmenElements" :key="slide">
                <div style="text-align: left; width:80%">
                  <img :src="slide.image" style="float: right; height: 200px;" class="rounded" />
                  <h2><span class="text-highlight">{{ slide.caption.substring(0,1) }}</span>{{ slide.caption.substring(1) }}</h2>
                  <p>{{ slide.subText }}</p>
                  <button v-if="slide.showProject" type="button" @click="$router.push(slide.link)" class="btn btn-menikheim">Zum Projekt</button>
                </div>
              </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
            <h1><span class="text-highlight">U</span>nsere Beispiele aus dem Bereich Wohnbau</h1>
            <p></p>
            <carousel :items-to-show="1" :wrapAround="true" :autoplay="7500" :pauseAutoplayOnHover="true" style="margin-top:2em;margin-bottom:2em;">
              <slide v-for="slide in wohnbauElements" :key="slide">
                <div style="text-align: left; width:80%">
                  <img :src="slide.image" style="float: right; height: 200px;" class="rounded" />
                  <h2><span class="text-highlight">{{ slide.caption.substring(0,1) }}</span>{{ slide.caption.substring(1) }}</h2>
                  <p>{{ slide.subText }}</p>
                  <button v-if="slide.showProject" type="button" @click="$router.push(slide.link)" class="btn btn-menikheim">Zum Projekt</button>
                </div>
              </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
            <h1><span class="text-highlight">A</span>ltbausanierung aus professioneller Hand</h1>
            <p></p>
            <carousel :items-to-show="1" :wrapAround="true" :autoplay="7500" :pauseAutoplayOnHover="true" style="margin-top:2em;margin-bottom:2em;">
              <slide v-for="slide in altbauElements" :key="slide">
                <div style="text-align: left; width:80%">
                  <img :src="slide.image" style="float: right; height: 150px;" class="rounded" />
                  <h2><span class="text-highlight">{{ slide.caption.substring(0,1) }}</span>{{ slide.caption.substring(1) }}</h2>
                  <p>{{ slide.subText }}</p>
                  <button v-if="slide.showProject" type="button" @click="$router.push(slide.link)" class="btn btn-menikheim">Zum Projekt</button>
                </div>
              </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

export default {
  name: "ReferenzenPage",
  components: {
    MenikheimLayout,
    Carousel, Slide, Navigation, Pagination
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getArticles() {
      return this.$store.getters.articles;
    }
  },
  data() {
    return {
      activeView: "MandantSelectorComponent",
      carouselElements: [
      {
          caption: "Jugendhaus Rudersberg",
          subText: "Modernisierung Jugendahus Rudersberg ",
          link: "/Projekt/Jugendhaus",
          image: require("@/assets/images/article/jugendhaus_rudersberg_4.jpg"),
          showProject: true
        },{
          caption: "Kinderhaus Welzheim",
          subText: "Erweiterung des Kindergartens Welzheim zur Kindertagesstätte ",
          link: "/Projekt/Kinderhaus",
          image: require("@/assets/images/article/kinderhaus-weilheim_teaser.jpg"),
          showProject: true
        },{
          caption: "Rathaus Alfdorf",
          subText: "Energetische Gebäudersanierung Rathaus der Gemeinde Alfdorf",
          link: "/Projekt/Alfdorf",
          image: require("@/assets/images/article/rathaus_alfdorf_teaser1.jpg"),
          showProject: true
        },{
          caption: "Rathaus Rudersberg",
          subText: "Fassadensanierung Rathaus der Gemeinde Rudersberg",
          link: "/Projekt/Rudersberg",
          image: require("@/assets/images/article/rathaus_rudersberg_teaser1.jpg"),
          showProject: true
        },{
          caption: "Festhalle Althütte",
          subText: "Energetische Gebäudesanierung Festhalle Althütte",
          link: "/Projekt/Althuette",
          image: require("@/assets/images/article/althuette_2-0f003e2a.jpg"),
          showProject: true
        },
      ],
      firmenElements: [
      {
          caption: "Volksbank Welzheim",
          subText: "Erweiterung des Bankhauses verbunden mit einer energetischen Gebäudesanierung",
          link: "/Projekt/VobaWelzheim",
          image: require("@/assets/images/article/voba_welzheim_teaser1.jpg"),
          showProject: true
        },{
          caption: "Kärcher Winnenden",
          subText: "Neubau des Kärcher Auditoriums",
          link: "/Projekt/KaercherWinnenden",
          image: require("@/assets/images/article/kaercher_auditorium_teaser.jpg"),
          showProject: true
        },{
          caption: "Edeka Rudersberg",
          subText: "Erweiterung des bestehenden Lebensmittelmarktes",
          link: "/Projekt/EdekaRudersberg",
          image: require("@/assets/images/article/edeka_ru_teaser.jpg"),
          showProject: true
        },{
          caption: "ZOTT Fitnessclub",
          subText: "Neubau eines Freizeit und Fitnesscenters in Weinstadt-Endersbach",
          link: "/Projekt/ZottFitness",
          image: require("@/assets/images/article/zot_teaser1.jpg"),
          showProject: true
        },{
          caption: "Firmenerweiterung Rudersberg Brühlstraße",
          subText: "Firmenerweiterung Rudersberg Brühlstraße",
          image: require("@/assets/images/article/img_0515.jpg"),
          showProject: false
        },
      ],
      wohnbauElements: [
      {
          caption: "Wohnhaus Rudersberg",
          subText: "Neubau eines Zweifamilienhauses in Rudersberg",
          link: "/Projekt/WohnRudersberg",
          image: require("@/assets/images/article/ru_eigen_teaser1.jpg"),
          showProject: true
        },{
          caption: "Wohnanlage Schorndorf",
          subText: "Neubau einer Wohnanlage am Stadtrand von Schorndorf",
          link: "/Projekt/WohnSchorndorf",
          image: require("@/assets/images/article/scheffelweg_teaser1.jpg"),
          showProject: true
        },{
          caption: "Hölderlin Carre | Fellbach",
          subText: "Neubau einer Wohnanlage im Zentrum der Stadt Fellbach",
          link: "/Projekt/Fellbach",
          image: require("@/assets/images/article/hoelderlin_teaser1.jpg"),
          showProject: true
        },{
          caption: "Römer Residenz",
          subText: "Neubau eines Wohnparkes in zentraler Lage der Stadt Rommelshausen",
          link: "/Projekt/Roemer",
          image: require("@/assets/images/article/residenz_ro_teaser.jpg"),
          showProject: true
        }
      ],
      altbauElements: [
      {
          caption: "Kirche Welzheim",
          subText: "Fassadensanierung des Eingangportales der Evangelischen Kirche in Welzheim",
          link: "/Projekt/KircheWelzheim",
          image: require("@/assets/images/article/kirche_welzheim_teaser.jpg"),
          showProject: true
        },{
          caption: "Kirchturm Rudersberg",
          subText: "Fachwerk- und Fassadensanierung der Johanneskirche in Rudersberg",
          link: "/Projekt/KirchturmRudersberg",
          image: require("@/assets/images/article/kirchturm_ru_teaser.jpg"),
          showProject: true
        },{
          caption: "Historisches Haus Rudersberg",
          subText: "Energetische Gebäudesanierung eines denkmalgeschützten Wohnhauses zum KFW-100 Haus",
          link: "/Projekt/HausRudersberg",
          image: require("@/assets/images/article/muehlbachweg_teaser.jpg"),
          showProject: true
        },{
          caption: "Cafe am Markt",
          subText: "Fassadensanierung eines historischen Fachwerksgebäudes im Herzen von Rudersberg",
          link: "/Projekt/CafeAmMarkt",
          image: require("@/assets/images/article/cafe_am_markt_teaser.jpg"),
          showProject: true
        }
      ]
    };
  },
};
</script>

<style scoped>

</style>
